import React, { useState } from 'react';
import { gql } from '../../../__generated__';
import { useQuery } from '@apollo/client';
import { ChildNumberLink } from '../links/ChildNumberLink';
import { BackToBackoffice } from '../links/BackToBackoffice';

const SUMMARY_QUERY = gql(`
query BackofficeTxSummary($year: Int!) {
  backofficeTxSummary(year: $year) {
    email
    value
    child
  }
}`);

export const BackofficeTransactionsSummary = () => {
  const actualYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState<number>(actualYear - 1);
  const { data } = useQuery(SUMMARY_QUERY, {
    variables: { year: selectedYear },
  });

  const possibleYears: number[] = [];
  for (let n = 2024; n <= actualYear; n += 1) {
    possibleYears.push(n);
  }

  const handleChange = (value: number) => {
    setSelectedYear(value);
  };

  return (
    <div>
      <BackToBackoffice />
      <h3>Přehled transakcí uživatele</h3>

      <p>
        Období{' '}
        <select
          value={selectedYear}
          onChange={(e) => handleChange(Number.parseInt(e.target.value))}
        >
          {possibleYears.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </p>
      <p>
        <table className="table table-bordered table-hover">
          <thead className="thead-dark">
            <tr>
              <td>Uživatel</td>
              <td>Děti</td>
              <td>Částka v CZK</td>
            </tr>
          </thead>
          <tbody>
            {data?.backofficeTxSummary.map((row) => (
              <tr key={row.email}>
                <td>
                  <a href={`/backoffice/user/${row.email}`}>{row.email}</a>
                </td>
                <td>
                  {row.child.map((ch) => (
                    <>
                      <ChildNumberLink
                        key={ch}
                        fullNumber={ch}
                      ></ChildNumberLink>{' '}
                    </>
                  ))}
                </td>
                <td style={{ textAlign: 'right' }}>{row.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </p>
    </div>
  );
};
