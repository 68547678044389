import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { GetAdoptionDetailsQuery } from '../../../__generated__/graphql';
import { useMutation } from '@apollo/client';
import { gql } from '../../../__generated__';

const CHANGE_PAYMENT_INTERVAL_MUTATION = gql(`
  mutation AdminAdoptionPaymentIntervalChange($adoptionId: String!, $paymentIntervalId: String!) {
    adminAdoptionPaymentIntervalChange(adoptionId: $adoptionId, paymentIntervalId: $paymentIntervalId) {
      success
    }
  }
`);

export default function ChangePaymentInterval(props: {
  adoptionId: string;
  paymentInterval: GetAdoptionDetailsQuery['adminAdoptionDetail']['paymentInterval'];
  paymentIntervals: GetAdoptionDetailsQuery['paymentIntervals'];
}) {
  const [show, setShow] = useState(false);

  const [selected, setSelected] = useState<
    GetAdoptionDetailsQuery['adminAdoptionDetail']['paymentInterval']
  >(props.paymentInterval);

  const [paymentIntervalMutation] = useMutation(
    CHANGE_PAYMENT_INTERVAL_MUTATION,
    {
      onCompleted: () => {
        setShow(false);
      },
      refetchQueries: ['getAdoptionDetails'],
    },
  );

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleChange = (paymentIntervalId: string) => {
    const newPaymentInterval = props.paymentIntervals.find(
      (paymentInterval) => paymentInterval.id === paymentIntervalId,
    );
    setSelected(newPaymentInterval);
    console.log('selected', newPaymentInterval);
  };

  const handleSave = () => {
    console.log('saving');
    if (selected?.id) {
      paymentIntervalMutation({
        variables: {
          adoptionId: props.adoptionId,
          paymentIntervalId: selected?.id,
        },
      });
    }
  };

  return (
    <>
      <span>
        <Button variant="primary" className="btn-sm" onClick={handleShow}>
          změnit
        </Button>
      </span>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Úprava platebního intervalu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Select
            defaultValue={selected?.id}
            onChange={(event) => handleChange(event.target.value)}
          >
            {props.paymentIntervals.map((paymentInerval) => (
              <option key={paymentInerval.id} value={paymentInerval.id}>
                {paymentInerval.treaty}
              </option>
            ))}
          </Form.Select>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Zavřít
            </Button>
            <Button variant="primary" onClick={() => handleSave()}>
              Uložit
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}
