import { useLazyQuery, useQuery } from '@apollo/client';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Pagination from '../../../components/Pagination';
import { gql } from '../../../__generated__';
import { ChildLink } from '../links/ChildLink';
import { formatChildState } from '../../../tools';
import { AdoptionLink } from '../links/AdoptionLink';
import { UserLink } from '../links/UserLink';
import { BackToBackoffice } from '../links/BackToBackoffice';
import { Form } from 'react-bootstrap';
import ExcelExport from '../../../components/common/ExcelExport';

const QUERY = gql(`
  query BackofficeChildList(
    $text: String
    $state: String
    $visible: Boolean
    $coordinatorId: String
  ) {
    backofficeChildList(
      input: { text: $text, visible: $visible, coordinatorId: $coordinatorId, state: $state }
    ) {
      id
      name
      number
      fullNumber
      state
      visible
      adoption {
        id
        user {
          email
        }
      }
      coordinator {
        id
        name
        sign
      }
    }
    listCoordinators {
      id
      name
      sign
    }
  }
`);

export default function BackofficeChildrenList() {
  const itemsPerPage = 30;

  const { page: currentPage = 1 } = useParams();
  const pageNumber = Number(currentPage) - 1;
  const navigate = useNavigate();
  const [itemOffset, setItemOffset] = useState(pageNumber * itemsPerPage);

  const [filterVisibility, setFilterVisibility] = useState<boolean | null>(
    true,
  );
  const [filterCoordinatorId, setFilterCoordinatorId] = useState<string | null>(
    null,
  );
  const [filterState, setFilterState] = useState<string | null>(null);
  const [filterText, setFilterText] = useState<string | null>(null);

  const [callQuery, { loading, error, data }] = useLazyQuery(QUERY, {
    variables: {
      text: filterText,
      state: filterState,
      visible: filterVisibility,
      coordinatorId: filterCoordinatorId,
    },
  });

  const callQueryWithFresh = () => {
    callQuery({
      variables: {
        text: filterText,
        state: filterState,
        visible: filterVisibility,
        coordinatorId: filterCoordinatorId,
      },
    });
  };

  useEffect(() => {
    callQueryWithFresh();
  }, [filterState, filterVisibility, filterCoordinatorId]);

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const children = data?.backofficeChildList;
  if (!children) {
    return <p>Chyba při načítání dat.</p>;
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = children.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(children.length / itemsPerPage);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % children.length;
    setItemOffset(newOffset);
    navigate(`/backoffice/children/page/` + (Number(event.selected) + 1));
  };

  const stateToColor = (state: string, visible?: boolean | null) => {
    if (!visible) {
      return 'table-light';
    }
    if (state == 'adopted') {
      return 'table-success';
    }
    if (state == 'for_adoption') {
      return 'table-danger';
    }
    if (state == 'reserved') {
      return 'table-warning';
    }
    return '';
  };

  const filterStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'none') {
      setFilterState(null);
    } else {
      setFilterState(event.target.value);
    }
  };

  const filterVisibilityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value === 'none') {
      setFilterVisibility(null);
    } else {
      setFilterVisibility(event.target.value === 'true');
    }
  };

  const filterTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setFilterText(null);
    } else {
      setFilterText(event.target.value);
    }
  };

  const filterCoordiantorIdChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value === 'none') {
      setFilterCoordinatorId(null);
    } else {
      setFilterCoordinatorId(event.target.value);
    }
  };

  const exportData = children.map((row) => {
    return {
      ...row,
      coordinator: row.coordinator?.name,
      coordinator_sign: row.coordinator?.sign,
      adoption: row.adoption?.user?.email,
      child_link: `http://pro-contact.cz/child/${row.fullNumber}`,
      user_link: row.adoption?.user?.email
        ? `http://pro-contact.cz/backoffice/user/${row.adoption?.user?.email}`
        : '',
      adoption_link: row.adoption
        ? `http://pro-contact.cz/backoffice/adoption/${row.adoption?.id}`
        : '',
    };
  });

  return (
    <div>
      <BackToBackoffice />
      <h3>Seznam dětí</h3>
      <div
        key="filter"
        className={'p-2 border-light bg-dark mb-3'}
        style={{ border: 'solid 1px black' }}
      >
        <h5>Filtr</h5>
        <div>Stav</div>
        <Form.Check
          key="none-radio1"
          inline
          label="(nerozhoduje)"
          name="group1"
          defaultChecked={filterState === null}
          value="none"
          onChange={filterStateChange}
          type="radio"
          id={`inline-radio-1`}
        />
        <Form.Check
          inline
          label="Adoptované"
          name="group1"
          type="radio"
          defaultChecked={filterState === 'adopted'}
          onChange={filterStateChange}
          value="adopted"
          id={`inline-radio-2`}
        />
        <Form.Check
          inline
          label="Rezervované"
          name="group1"
          type="radio"
          defaultChecked={filterState === 'reserved'}
          onChange={filterStateChange}
          value="reserved"
          id={`inline-radio-3`}
        />
        <Form.Check
          inline
          label="K adoptování"
          name="group1"
          type="radio"
          defaultChecked={filterState === 'for_adoption'}
          onChange={filterStateChange}
          value="for_adoption"
          id={`inline-radio-4`}
        />
        <div>Viditelnost</div>
        <Form.Check
          key="none-visibility"
          inline
          label="(nerozhoduje)"
          name="group2"
          value="none"
          defaultChecked={filterVisibility === null}
          onChange={filterVisibilityChange}
          type="radio"
          id={`inline-visible-none`}
        />
        <Form.Check
          inline
          label="Viditelné"
          name="group2"
          type="radio"
          value="true"
          onChange={filterVisibilityChange}
          defaultChecked={filterVisibility === true}
          id={`inline-visible`}
        />
        <Form.Check
          inline
          label="Neviditelné"
          name="group2"
          type="radio"
          value="false"
          onChange={filterVisibilityChange}
          defaultChecked={filterVisibility === false}
          id={`inline-invisible`}
        />
        <div>Koordinátor</div>
        <Form.Check
          key="none-coor"
          inline
          defaultChecked={filterCoordinatorId === null}
          onChange={filterCoordiantorIdChange}
          label="(nerozhoduje)"
          name="group3"
          value="none"
          type="radio"
          id={`coor-id-none`}
        />
        {data?.listCoordinators.map((coordinator) => (
          <Form.Check
            key={coordinator.id}
            inline
            label={`${coordinator.sign} (${coordinator.name})`}
            defaultChecked={filterCoordinatorId === coordinator.id}
            onChange={filterCoordiantorIdChange}
            name="group3"
            type="radio"
            value={coordinator.id}
            id={`coor-id-${coordinator.id}`}
          />
        ))}
        <div>Vyhledávání</div>
        <input
          type="text"
          value={filterText ?? ''}
          onChange={filterTextChange}
          onBlur={() => callQueryWithFresh()}
        />
      </div>

      <p>Celkem zobrazených záznamů: {children.length}</p>
      <div>
        <ExcelExport data={exportData} fileName="children_list" />
      </div>

      <Pagination
        handlePageClick={handlePageClick}
        pageCount={pageCount}
        pageNumber={pageNumber}
        pageUrlPrefix={`/backoffice/children`}
      ></Pagination>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Znak</th>
            <th scope="col">Číslo</th>
            <th scope="col">Jméno</th>
            <th scope="col">Rodič</th>
            <th scope="col">Koordinátor</th>
            <th scope="col">Stav</th>
            <th scope="col">Akce</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((child) => (
            <tr
              key={child.id}
              className={stateToColor(child.state, child.visible)}
            >
              <td>{child.coordinator?.sign}</td>
              <td>{child.number}</td>
              <td>
                <ChildLink
                  name={child.name}
                  fullNumber={child.fullNumber}
                ></ChildLink>
              </td>
              <td>
                {child.adoption?.user && (
                  <UserLink email={child.adoption.user.email} />
                )}
              </td>
              <td>{child.coordinator?.name}</td>
              <td>
                {child.visible
                  ? formatChildState(child.state, false)
                  : 'neviditelné'}
              </td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pt-2">
        <Pagination
          handlePageClick={handlePageClick}
          pageCount={pageCount}
          pageNumber={pageNumber}
          pageUrlPrefix={`/backoffice/children`}
        ></Pagination>
      </div>
    </div>
  );
}
