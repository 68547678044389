import { Link } from 'react-router-dom';
import React from 'react';

export const BackToBackoffice = () => {
  return (
    <div className="mb-2">
      <Link to="/backoffice">« zpět do backoffice</Link>
    </div>
  );
};
