import { Form } from 'react-bootstrap';
import React, { useState } from 'react';
import { gql } from '../../../__generated__';
import { useMutation } from '@apollo/client';

const MUTATION_SKIP_PAYMENT_REQUEST = gql(`
  mutation AdminSkipPaymentRequest($paymentRequestId: String! $skip: Boolean!) {
    adminSkipPaymentRequest(paymentRequestId: $paymentRequestId, skip: $skip) {
      success
    }
  }
`);

export default function PaymentRequestSkipRadio(props: {
  id: string;
  skip: boolean;
}) {
  const [skip, setSkip] = useState<boolean>(props.skip);

  const [mutationSkip] = useMutation(MUTATION_SKIP_PAYMENT_REQUEST);

  const handleChangeSkip = (newSkip: boolean) => {
    setSkip(newSkip);
    mutationSkip({ variables: { paymentRequestId: props.id, skip: newSkip } });
  };

  return (
    <Form.Check
      type="checkbox"
      id={`default-${props.id}`}
      value={props.id}
      checked={skip}
      onChange={(event) => handleChangeSkip(event.target.checked)}
    />
  );
}
