import React, { useState } from 'react';
import { gql } from '../../../__generated__';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import RemindersTable from '../../../components/common/RemindersTable';
import PaymentRequestSkipRadio from './paymentRequestSkip';
import ChangePaymentInterval from './changePaymentInterval';
import { GetAdoptionDetailsQuery } from '../../../__generated__/graphql';
import { AdoptionCancelButton } from './cancel';
import { BackToBackoffice } from '../links/BackToBackoffice';

const GET_ADOPTION_DETAILS = gql(`
  query getAdoptionDetails($adoptionId: String!) {
    adminAdoptionDetail(adoptionId: $adoptionId) {
      id
      user {
        email
      }
      child {
        name
        fullNumber
      }
      paymentRequests {
        id
        paymentDate
        paid
        skip
        intervalDate {
          paymentInterval {
            id
            treaty
            price
            intervalReminders {
              id
              daysBefore
            }
          }
        }
        reminders {
          remindedAt
          intervalReminder {
            id
            daysBefore
          }
        }
      }
      paymentInterval {
        id
        treaty
      }
      lastPayment
      lastPaymentTx
      nextPayment
    }
    
    paymentIntervals {
      id
      treaty
    }
  }
`);

export default function AdoptionDetail() {
  const { adoptionId } = useParams();

  const [paymentIntervals, setPaymentIntervals] = useState<
    GetAdoptionDetailsQuery['paymentIntervals']
  >([]);

  const { loading, error, data } = useQuery(GET_ADOPTION_DETAILS, {
    onCompleted: (data) => {
      setPaymentIntervals(data.paymentIntervals);
    },
    variables: { adoptionId: adoptionId ?? '' },
  });

  if (loading || !data) return <p>Nahrává se...{adoptionId}</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const adoption = data.adminAdoptionDetail;

  return (
    <div className="page-detail">
      <div className="page-content">
        <div className="container mt-4">
          <BackToBackoffice />
          <div style={{ width: '100%' }}>
            {adoption.child && adoption.user && (
              <div style={{ float: 'right' }}>
                <AdoptionCancelButton
                  adoptionId={adoption.id}
                  childFullNumber={adoption.child?.fullNumber}
                />
              </div>
            )}
            <h2>Adopce - {adoption.child?.fullNumber}</h2>
          </div>

          <div className="card mb-3">
            <div className="card-body">
              <h4>Detaily</h4>
              <p>
                <strong>Rodič:</strong>{' '}
                <a
                  style={{ color: 'darkgreen' }}
                  href={`/backoffice/user/${adoption.user?.email}`}
                >
                  {adoption.user?.email}
                </a>
              </p>
              <p>
                <strong>Jméno dítěte:</strong>{' '}
                <a
                  style={{ color: 'darkgreen' }}
                  href={`/child/${adoption.child?.fullNumber}`}
                >
                  {adoption.child?.name} ({adoption.child?.fullNumber})
                </a>
              </p>
              {adoption.lastPayment && (
                <p>
                  <strong>Poslední zaplacená žádost:</strong>{' '}
                  {new Date(adoption.lastPayment).toLocaleDateString()}
                  {adoption.lastPaymentTx && (
                    <span>
                      {' '}
                      <strong>Zaplaceno transakcí:</strong>{' '}
                      {new Date(adoption.lastPaymentTx).toLocaleDateString()}{' '}
                      {new Date(adoption.lastPaymentTx).toLocaleTimeString()}
                    </span>
                  )}
                </p>
              )}
              {adoption.nextPayment && (
                <p>
                  <strong>Následující zádost:</strong>{' '}
                  {new Date(adoption.nextPayment).toLocaleDateString()}
                </p>
              )}

              <p>
                <strong>Platební interval:</strong>{' '}
                {adoption.paymentInterval?.treaty}
                {adoption && adoption.paymentInterval && paymentIntervals && (
                  <ChangePaymentInterval
                    adoptionId={adoption.id}
                    paymentInterval={adoption.paymentInterval}
                    paymentIntervals={paymentIntervals}
                  ></ChangePaymentInterval>
                )}
              </p>
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-body">
              <h4>Žádosti o platbu</h4>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Datum žádosti</th>
                    <th>Částka</th>
                    <th>Přeskočit</th>
                    <th>Zaplaceno</th>
                    <th>
                      Notifikace{' '}
                      <span className="fw-normal">[počet dní předem]</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {adoption.paymentRequests?.map((request) => (
                    <tr
                      key={request.id}
                      className={
                        request.paid ? 'table-success' : 'table-danger'
                      }
                    >
                      <td>
                        {new Date(request.paymentDate).toLocaleDateString()}
                      </td>
                      <td>
                        {request.intervalDate.paymentInterval.price}&nbsp;Kč
                      </td>
                      <td>
                        <PaymentRequestSkipRadio
                          id={request.id}
                          skip={request.skip}
                        ></PaymentRequestSkipRadio>
                      </td>
                      <td>{request.paid ? 'Ano' : 'Ne'}</td>
                      <td>
                        <RemindersTable
                          intervalReminders={
                            request.intervalDate.paymentInterval
                              .intervalReminders
                          }
                          paymentReminders={request.reminders}
                        ></RemindersTable>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
