import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '../../../__generated__';
import FileUpload from './FileUpload';
import { BackToBackoffice } from '../links/BackToBackoffice';

const GET_BANK_CERTIFICATES = gql(`
  query GetBankCertificates {
    bankCertificates {
      id
      filename
      active
      createdAt
      updatedAt
    }
  }
`);

export function BankCertificates() {
  const { loading, error, data } = useQuery(GET_BANK_CERTIFICATES);

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const certs = data?.bankCertificates ?? [];

  if (certs === null) {
    return <p>Chyba při načítání dat.</p>;
  }

  return (
    <div className="page-detail">
      <BackToBackoffice />
      <h3>Bankovní certifikáty</h3>
      <div className="page-content">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Vytvořeno</th>
              <th scope="col">Název souboru</th>
              <th scope="col">Aktivní</th>
            </tr>
          </thead>
          <tbody>
            {certs.map((cert) => (
              <tr key={cert.id}>
                <th scope="row">{cert.id}</th>
                <td>{cert.createdAt}</td>
                <td>{cert.filename}</td>
                <td>{cert.active ? 'Aktivní' : 'Neaktivní'}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>
          <FileUpload></FileUpload>
        </p>
      </div>
    </div>
  );
}
