import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '../../../__generated__';
import { AdoptionLink } from '../links/AdoptionLink';
import { ChildLink } from '../links/ChildLink';
import { UserLink } from '../links/UserLink';
import { ChildNumberLink } from '../links/ChildNumberLink';
import { BackToBackoffice } from '../links/BackToBackoffice';

const QUERY = gql(`
  query BackofficeExpiredPaymentRequests {
    backofficeExpiredPaymentRequests {
      id
      childNumber
      childName
      reserved
      adoptionId
      email
      interval
      paymentDate
      daysLate
    }
  }
`);

export const BackofficeExpiredPaymentRequests = () => {
  const { loading, error, data } = useQuery(QUERY);

  const expiredRequests = data?.backofficeExpiredPaymentRequests;

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;
  if (expiredRequests === undefined) {
    return <p>Chyba</p>;
  }

  return (
    <>
      <BackToBackoffice />
      <h3>Expirované žádosti o platbu</h3>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Dítě</th>
            <th scope="col">Rodič</th>
            <th scope="col">Rezervace</th>
            <th scope="col">Adopce</th>
            <th scope="col">Datum žádosti</th>
            <th scope="col">Expirováno dní</th>
          </tr>
        </thead>
        <tbody>
          {expiredRequests.map((expiredRequest) => (
            <tr key={expiredRequest.id}>
              <td>
                {expiredRequest.childNumber && (
                  <ChildNumberLink fullNumber={expiredRequest.childNumber} />
                )}
              </td>
              <td>
                <UserLink email={expiredRequest.email}></UserLink>
              </td>
              <td>{expiredRequest.reserved ? 'Rezervace' : 'Adopce'}</td>
              <td>
                {expiredRequest.adoptionId && (
                  <AdoptionLink adoptionId={expiredRequest.adoptionId} />
                )}
              </td>
              <td>
                {new Date(expiredRequest.paymentDate).toLocaleDateString()}
              </td>
              <td>{expiredRequest.daysLate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
