import React, { useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { gql, useLazyQuery } from '@apollo/client';
import { BackToBackoffice } from '../links/BackToBackoffice';

const GET_RESULT = gql`
  query AdminSearchUserEmail($childNumbers: String, $state: String) {
    adminSearchUserEmail(childNumbers: $childNumbers, state: $state)
  }
`;

interface IResult {
  adminSearchUserEmail: string;
}

export default function BackofficeUserEmailSearch() {
  const [key, setKey] = useState<string>('home');

  const [result, setResult] = useState<string>('');
  const [batch, setBatch] = useState<string>('');

  const [getResult] = useLazyQuery<IResult>(GET_RESULT);

  const onClick = async () => {
    await getResult({
      variables: { childNumbers: batch },
      onCompleted: (data) => {
        setResult(data.adminSearchUserEmail);
      },
    });
  };

  const onClick2 = async () => {
    await getResult({
      variables: { state: 'adopted' },
      onCompleted: (data) => {
        setResult(data.adminSearchUserEmail);
      },
    });
  };

  return (
    <div>
      <BackToBackoffice />
      <h3>Výpis emailů</h3>

      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => k && setKey(k)}
      >
        <Tab eventKey="home" title="Dle čísel dětí">
          <div className="form-group">
            <label htmlFor="seznamDeti">Seznam čísel dětí</label>
            <textarea
              className="form-control"
              id="seznamDeti"
              rows={10}
              value={batch}
              onChange={(e) => {
                setBatch(e.target.value);
              }}
            ></textarea>
            <span className="form-text text-white">
              Vkládejte čísla dětí oddělené klávesou ENTER.
            </span>
          </div>
          <div className="pt-3 pb-3">
            <Button onClick={onClick} variant="primary">
              Zobrazit
            </Button>
          </div>
        </Tab>
        <Tab eventKey="profile" title="Adoptivní rodiče">
          <div className="pt-3 pb-3">
            <Button onClick={onClick2} variant="primary">
              Zobrazit
            </Button>
          </div>
        </Tab>
      </Tabs>

      <div className="form-group mt-3">
        <h4>Výsledek</h4>
        <textarea
          className="form-control"
          id="vysledek"
          rows={5}
          value={result}
          readOnly={true}
        ></textarea>
      </div>
    </div>
  );
}
