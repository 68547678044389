import React, { useState } from 'react';
import { getBackendUrl } from '../../../tools';
import { Card } from 'react-bootstrap';
import { client } from '../../../index';

const FileUpload: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [passphrase, setPassphrase] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  // Handle file selection
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : null;
    setFile(selectedFile);
  };

  // Handle form submission and file upload
  const handleUpload = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!file) {
      setMessage('Vyberte soubor s příponou p12 k nahrání.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('passphrase', passphrase);

    try {
      const response = await fetch(
        getBackendUrl() + '/bank-certificate/upload',
        {
          method: 'POST',
          body: formData,
        },
      );

      if (response.ok) {
        setMessage('Certifikát byl úspěšně nahrán.');
        await client.refetchQueries({ include: ['GetBankCertificates'] });
      } else {
        setMessage(
          'Chyba při nahrávání certifikátu. Zkuste zkontrolovat heslo.',
        );
      }
    } catch (error) {
      console.error('Chyba při nahrávání certifikátu:', error);
      setMessage('Chyba při nahrávání certifikátu');
    }
  };

  return (
    <div className="container mt-1">
      <Card className="p-3">
        <h5>Nahrát nový certifikát</h5>
        <form onSubmit={handleUpload}>
          <div className="mb-3">
            <label htmlFor="formFile" className="form-label">
              Vyberte soubor
            </label>
            <input
              type="file"
              className="form-control"
              id="formFile"
              onChange={handleFileChange}
            />
            <label htmlFor="passphrase" className="form-label">
              Heslo k certifikátu
            </label>
            <input
              type="text"
              className="form-control"
              id="passphrase"
              value={passphrase}
              onChange={(event) => setPassphrase(event.target.value)}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Nahrát
          </button>
        </form>
        {message && <div className="mt-3 alert alert-info">{message}</div>}
      </Card>
    </div>
  );
};

export default FileUpload;
