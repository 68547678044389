import React, { useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'easymde/dist/easymde.min.css';
import { gql, useMutation } from '@apollo/client';
import { Button } from 'react-bootstrap';
import { BackToBackoffice } from './links/BackToBackoffice';

const ATTACH_VIDEO_LINK = gql`
  mutation AttachVideoLink(
    $childNumber: Int!
    $groupName: String!
    $link: String!
  ) {
    attachVideoLink(
      input: {
        childNumber: $childNumber
        attachmentGroup: $groupName
        link: $link
      }
    ) {
      success
      message
    }
  }
`;

interface IAttachVideoLink {
  attachVideoLink: {
    success: boolean;
    message: string;
  };
}

export default function YouTubeUpload() {
  const [group, setGroup] = useState<string>('');
  const [batch, setBatch] = useState<string>('');
  const [progress, setProgress] = useState<number | null>(null);
  const [logs, setLogs] = useState<string[]>([]);

  const [attachVideoLinkMutation] =
    useMutation<IAttachVideoLink>(ATTACH_VIDEO_LINK);

  const onClick = async () => {
    const actualLogs: string[] = [];
    const lines = batch.split('\n');
    let i = 0;
    for (const line of lines) {
      i += 1;
      const [childNumber, link] = line.split(/\s+/);
      if (childNumber && link) {
        try {
          const result = await attachVideoLinkMutation({
            variables: {
              childNumber: Number.parseInt(childNumber),
              groupName: group,
              link: link,
            },
          });
          const resultText = result.data?.attachVideoLink?.success
            ? 'Ok'
            : 'Chyba';
          actualLogs.push(
            `Dítě ${Number.parseInt(childNumber)} - ${resultText} - ${result.data?.attachVideoLink?.message}`,
          );
        } catch (err) {
          actualLogs.push(`Dítě ${Number.parseInt(childNumber)} - ${err}`);
        }
      } else {
        actualLogs.push(`Řádek "${line}" - chyba při parsování`);
      }
      setLogs(actualLogs);
      setProgress(Math.floor((100 * i) / lines.length));
    }
    setProgress(null);
  };

  return (
    <div>
      <BackToBackoffice />
      <h3>Upload YouTube videí</h3>

      <div className="form-group">
        <label htmlFor="nazevSkupinyId">Název skupiny</label>
        <input
          type="text"
          className="form-control"
          id="nazevSkupinyId"
          value={group}
          onChange={(e) => {
            setGroup(e.target.value);
          }}
        />
      </div>

      <div className="form-group">
        <label htmlFor="seznamVidei">Seznam</label>
        <textarea
          className="form-control"
          id="seznamVidei"
          rows={15}
          value={batch}
          onChange={(e) => {
            setBatch(e.target.value);
          }}
        ></textarea>
        <span className="form-text text-white">
          Vkládejte řádky ve formátu:{' '}
          <pre>číslo dítěte (mezera) youtube odkaz</pre>
        </span>
      </div>

      <div className="pt-3 pb-3">
        <Button onClick={onClick} variant="primary">
          Odeslat
        </Button>
      </div>

      {progress && (
        <ProgressBar animated now={progress} label={`${progress}%`} />
      )}
      <div>
        <h4>Logs:</h4>
        <ul>
          {logs.map((log) => (
            <li key={log}>{log}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
